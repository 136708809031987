.player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

.orfeo-form-check-input > [type="checkbox"] {
    border-radius: 0.8em !important;
    padding: 9px !important;
}