@import "../../../node_modules/bootstrap/scss/functions";



@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

// @font-face {
//   font-family: "AL Murberry Bold";
//   src: url('./assets/fonts/Al_Murberry_Bold.ttf') format('truetype');
//   font-weight: bold;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Assistant Bold";
//   src: url('./assets/fonts/Assistant-Bold.ttf') format('truetype');
//   font-weight: bold;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Assistant Regular";
//   src: url('./assets/fonts/Assistant-Regular.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

$derive-brand-violet: #080277;
$derive-brand-green: #6886c5;
$derive-brand-magenta: #4230ce;
$derive-brand-light-green: #EBF2DF;
$derive-brand-midnight: #141422;

$orfeo-light-blue:#689bc5;
$orfeo-beige: #EBF2DF;
$orfeo-dark-blue: #020277;


.orfeo-bg {
  background-color: rgba(desaturate($derive-brand-violet, 60%), 1.0);
}

.orfeo-biege-bg {
  background-color: rgba($orfeo-beige, 0.3);
}

.card {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

// Default variable overrides
$body-bg: rgba(desaturate($orfeo-beige, 10%), 0);
$body-color: $orfeo-dark-blue;

$form-check-input-checked-bg-color: lighten($orfeo-dark-blue, 10%);
$form-check-input-bg: lighten($orfeo-beige, 10%);

$font-family-sans-serif: "AL Murberry Bold", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-base:       $font-family-sans-serif !default;

$primary: $derive-brand-violet;
$success: $derive-brand-green;
$danger: $derive-brand-magenta;

$progress-bar-bg: $derive-brand-green;

$card-border-radius: 0.5em;


$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);
@import "../../../node_modules/bootstrap/scss/maps";
@import "../../../node_modules/bootstrap/scss/root";
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "../../../node_modules/bootstrap/scss/bootstrap-grid";

body {
  //background-image: url(./assets/hydrate/splash.jpeg);
  background-color: $orfeo-beige;
  background-position-y: bottom;
  background-size: 100vw 100vh;
  background-repeat: no-repeat;
  
}

.bg-orfeo-blue {
  background-color: $orfeo-light-blue ;
}

.bg-orfeo-blue-50 {
  background-color: rgba($orfeo-light-blue, 0.7) ;
}

.bg-orfeo-blue-50 {
  .navbar-toggler {
    background-color: rgba($orfeo-light-blue, 0.8) ;
  }
}

.bg-orfeo-beige {
  background-color: $orfeo-beige ;
}

.orfeo-nav {
  font-family: $font-family-sans-serif;
  font-weight: 600;
  background-color: $derive-brand-violet;
}

.nav-link  {

  color: white !important;
}


.nav-logo {
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
}

.nav-logo span {

  font-weight: 700;
  font-size: 1.5em;
  margin-left: 5px;
  color: white !important;
}


.nav-logo-img {
  height: 50px;
}


.orfeo-styled-table > tbody > tr > * {
  color: lighten($orfeo-beige, 10%) !important;
}

.orfeo-styled-table {
  font-family: $font-family-sans-serif !important;
  color: lighten($orfeo-beige, 10%) !important;
}
.orfeo-styled-btn-light {
  background-color: lighten($orfeo-light-blue, 10%) !important;
  border-color: transparent !important;
  color: darken($orfeo-dark-blue, 20%) !important;
}

.orfeo-styled-btn-dark {
  color: lighten($orfeo-light-blue, 10%) !important;
  border-color: transparent !important;
  background-color: darken($orfeo-dark-blue, 5%) !important;
}

.orfeo-styled-btn-highlight {
  background-color: lighten($orfeo-light-blue, 20%) !important;
  border-color: transparent !important;
  color: darken($orfeo-dark-blue, 5%) !important;
}

.orfeo-table-header-row {
  background-color: lighten($orfeo-dark-blue, 10%) !important;
  border-radius: 5px;
  color: darken($orfeo-dark-blue, 20%) !important;
}

.orfeo-table-container {
  background-color: desaturate($orfeo-dark-blue, 10%) !important;
  color: darken(white, 20%) !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.orfeo-select {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  
}

.orfeo-radar-chart-container {
  background-color: desaturate($orfeo-dark-blue, 10%) !important;
}


text {
  fill: lighten($orfeo-beige, 10%) !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: darken($derive-brand-light-green, 30%);
  border-radius: 10px;
}



@import "./pages/styles/client.scss";

@import "./pages/styles/patient-dashboard.scss"