
.orfeo-header-top-row {
    padding: 10px;
    background-color: darken($orfeo-dark-blue, 20%) !important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    color: lighten($orfeo-beige, 10%) !important;
}

.orfeo-header-mid-row {
    padding: 10px;
    background-color: darken($orfeo-dark-blue, 20%) !important;
    color: lighten($orfeo-beige, 10%) !important;
}


.orfeo-content-row {
    background-color: $orfeo-dark-blue;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.orfeo-body-row {
     background-color: darken($orfeo-dark-blue, 10%) !important;
}

.orfeo-questionnaire {
    background-color: darken($orfeo-beige, 10%) !important;
    padding: 10px;
    border-radius: 10px;
}

.questionnaire-option {
    border-radius: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.questionnaire-btn-grp {
    align-self: center;
}

.questionnaire-btn-grp .btn {
    
    border-radius: 50px;
    height: 2.5em;
}

.questionnaire-title {
    font-size: 1.5em;
    padding: 20px 0px;
    font-weight: bold;
    color: darken($orfeo-dark-blue, 10%) !important;
}

.orfeo-questionnare-bg {
    background-color: rgba($derive-brand-light-green, 0.8) !important;
    color: $derive-brand-midnight;
    border-radius: 0.5em;
}

.orfeo-questionnare-bg.container {
    color: $derive-brand-midnight;
    
    background-color: rgba($derive-brand-light-green, 0.8) !important;
}